export const MainNav = [
    {
        icon: 'lnr-database',
        label: 'Tenants',
        to: '#/tenants',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Feedback',
        to: '#/feedback',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Class',
        to: '#/classes',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Language',
        to: '#/language',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Subject',
        to: '#/subject',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Chapters',
        to: '#/chapters',
    },
    {
        icon: 'pe-7s-note2',
        label: 'Admin Profile',
        to: '#/profile',
    },
    {
        icon: 'lnr-database',
        label: 'Packages',
        to: '#/package',
    },
    {
        icon: 'lnr-database',
        label: 'Coupons',
        to: '#/coupons',
    },
    {
        icon: 'lnr-cog',
        label: 'Guide',
        to: '#/guide',
    }
];
